<template>
  <div v-loading="loading">
    <el-breadcrumb
        class="m_tb_10"
        separator="/">
      <el-breadcrumb-item :to="{ path: '/party/trainingLearning' }">
        学习培训
      </el-breadcrumb-item>
      <el-breadcrumb-item>培训详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-box bg_fff m_t_20">
      <div class="title-box m_b_20 fw_bold">{{ resourceData.title }}</div>
      <div class="resource-box">
        <div style="width: 888px; margin: 0 auto; position: relative;display:flex">
          <img
              id="resource"
              class="resource"
              :src="resourceData.coverUrl"
              alt=""
              v-if="!resourceData.url || resourceData.type == -1"/>
          <video
              id="resource"
              controls
              class="resource"
              :src="resourceData.url"
              v-else-if="resourceData.type == 3"></video>
          <div
              v-show="resourceData.state == 'underway'"
              class="mask"
              ref="mask">
            <el-button
                type="danger"
                class="study-btn"
                @click="study_click">
              立即学习
            </el-button>
          </div>
        </div>
        <div
            v-show="resourceData.describes"
            style="text-align: center; font-size: 14px; margin-top:20px">
          （{{ resourceData.describes }}）
        </div>
      </div>
      <div class="introduce-box p_in_20 fs_16">
        <span class="fw_bold">任务详情：</span>
        {{ resourceData.content }}
      </div>
    </div>
    <div
        class="operation-box flex_row"
        v-show="resourceData.state != 'waiting'">
      <div class="inspiration-box p_in_20 m_t_20 fs_18 fw_bold bg_fff flex_col">
        <p>学习感悟</p>
        <div class="inspiration-item-box flex_row m_tb_20">
          <img
              class="avatar m_r_10"
              :src="avatar"
              alt=""/>
          <el-input
              type="textarea"
              placeholder="请输入学习感悟"
              :rows="6"
              resize="none"
              v-model="perceptionValue"></el-input>
        </div>
        <el-button
            class="submit-btn"
            type="danger"
            size="small"
            @click="submitPerception_click">
          发布
        </el-button>
      </div>
      <div
          class="experience-box p_in_20 m_t_20 bg_fff flex_col"
          v-if="limit != 0">
        <p class="fs_18 fw_bold">学习心得</p>
        <div class="experience-item-box flex_col br_6">
          <i class="el-icon-folder-opened fs_24"></i>
          <el-button
              type="danger"
              size="small"
              plain
              @click="showDialog_click">
            上传文档
          </el-button>
          <p
              class="fs_12"
              style="color: #dd2622">
            还可以上传{{ limit }}个文件
          </p>
        </div>
      </div>
    </div>
    <div
        class="exhibition-box m_tb_20 bg_fff p_in_20"
        v-show="resourceData.state != 'waiting'">
      <template v-if="userPerceptionData.length > 0">
        <p class="fs_18 fw_bold">我的感悟</p>
        <div
            class="exhibition-item-box m_tb_10 flex_col"
            v-for="data in userPerceptionData"
            :key="data.createTime">
          <div class="info-box flex_row">
            <div
                class="flex_row"
                style="align-items: center">
              <img
                  class="avatar m_r_10"
                  :src="avatar"
                  alt=""/>
              <span class="fs_16 fw_bold m_r_10">{{ data.userName }}老师</span>
              <span class="fs_14">{{ data.deptName }}</span>
              <span
                  class="fs_12 m_l_10"
                  :style="`color:${colorJSON[data.isExamine]}`">
                {{ typeJSON[data.isExamine] }}
              </span>
            </div>
            <el-button
                v-show="data.userId == userInfo.user_id"
                type="danger"
                plain
                size="mini"
                @click="deletePerception_click(data)">
              删除
            </el-button>
          </div>
          <div class="exhibition-content fs_14 c_666">
            {{ data.content }}
          </div>
        </div>
      </template>
      <template v-if="Object.keys(userExperienceData).length !== 0">
        <p class="fs_18 fw_bold">我的心得</p>
        <div
            class="exhibition-item-box m_tb_10 flex_col"
            title="学习心得">
          <el-descriptions
              direction="vertical"
              :column="2"
              border>
            <!--            <el-descriptions-item label="姓名">-->
            <!--              {{ userExperienceData.userName }}-->
            <!--            </el-descriptions-item>-->
            <el-descriptions-item label="学习心得">
              <a
                  :href="url"
                  target="_blank"
                  v-for="(url, index) in userExperienceData.previewUrl"
                  :key="index">
                文件{{ index + 1 }}
              </a>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>
      <p class="fs_18 fw_bold">学习感悟</p>
      <template v-if="perceptionData.length > 0">
        <div
            class="exhibition-item-box m_tb_10 flex_col"
            v-for="data in perceptionData"
            :key="data.id">
          <div class="info-box flex_row">
            <div
                class="flex_row"
                style="align-items: center">
              <img
                  class="avatar m_r_10"
                  :src="avatar"
                  alt=""/>
              <span class="fs_16 fw_bold m_r_10">{{ data.userName }}老师</span>
              <span class="fs_14">{{ data.deptName }}</span>
              <span
                  class="fs_12 m_l_10"
                  :style="`color:${colorJSON[data.isExamine]}`">
                {{ typeJSON[data.isExamine] }}
              </span>
            </div>
          </div>
          <div class="exhibition-content fs_14 c_666">
            {{ data.content }}
          </div>
          <el-button
              class="show-experience-btn"
              type="danger"
              size="small"
              @click="showExperience_click(data.userId,true)">
            查看学习心得
          </el-button>
        </div>
        <div class="paging">
          <el-pagination
              @current-change="current_change"
              :page-size="datumParams.size"
              :background="true"
              layout="total, prev, pager, next"
              :total="datumParams.total"></el-pagination>
        </div>
      </template>
      <el-empty v-else></el-empty>
    </div>
    <!-- 上传学习心得 -->
    <el-dialog
        width="40%"
        :visible.sync="showDialog"
        title="上传学习心得">
      <Simple
          ref="upload"
          :headers="headers"
          :before-upload="beforeUpload"
          :accept="accepts"
          :upload-arguments="uploadArgumentsObj"
          :limit="limit"
          :on-exceed="fileLimitFn"
          :base-url="baseUrl"
          :chunk-size="chunkSize"
          @success="success"
          @clearFiles="clear"></Simple>
    </el-dialog>
    <!-- 查看学习心得 -->
    <el-dialog
        width="40%"
        :visible.sync="showExperience"
        title="查看学习心得">
      <el-descriptions
          direction="vertical"
          :column="2"
          border
          v-if="experienceData">
        <el-descriptions-item label="姓名">
          {{ experienceData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="学习心得">
          <a
              :href="url"
              target="_blank"
              v-for="(url, index) in experienceData.previewUrl"
              :key="index">
            文件{{ index + 1 }}
          </a>
        </el-descriptions-item>
      </el-descriptions>
      <el-empty v-else></el-empty>
    </el-dialog>
  </div>
</template>

<script>
import Simple from '@/components/simple-upload/simple';
import {sessionGet} from '@/utils/local';
import {
  addLearningPerception,
  addPartyStudyExperience,
  deleteLearningPerception,
  getDetail,
  getPartyStudyExperience,
  getPartyStudyPerception,
  getUserExperience,
  getUserPerception,
  recordUserStudy,
  updatePartyStudyExperience,
} from '@/api/party/index';
import avatar from '@/assets/img/avatar.png';

export default {
  name: 'Detail',
  components: {Simple},
  data() {
    return {
      avatar,
      loading: false,
      resourcesId: null,
      resourceData: {},
      userExperienceData: {},
      userPerceptionData: [],
      showDialog: false,
      resourceParams: {
        resourcesId: '',
        userId: '',
        url: '',
        isExamine: 2,
        status: 2,
        type: 5,
      },
      datumParams: {
        resourcesId: '',
        isExamine: 1,
        size: 10,
        current: 1,
      },
      experienceData: [],
      perceptionData: [],
      perceptionValue: null,
      showExperience: false,
      // 大文件上传
      accepts: 'image/*,.doc,.docx,.pdf', //上传文件类型
      uploadArguments: {
        //临时自测使用
        type: 'zip',
      },
      share: 1, // 是否共享 0私有  1共享
      limit: 5,
      chunkSize: 10 * 1024 * 1024, // 分片大小
      resourcesUrlHide: false, //文件地址显示
      filesLength: 0,
      colorJSON: {
        2: '#3290FF',
        1: '#01CD9E',
        3: '#DD2622',
      },
      typeJSON: {
        2: '待审核',
        1: '审核通过',
        3: '审核未通过',
      },
      userInfo: {},
    };
  },
  computed: {
    headers() {
      return {
        Authorization: 'token',
      };
    },
    uploadArgumentsObj() {
      return {...this.uploadArguments, share: this.share};
    },
    baseUrl() {
      return '';
    },
  },
  async mounted() {
    this.userInfo = sessionGet('userInfo');
    this.resourceParams.userId = this.userInfo.userId;
    let id = this.$route.query.id;
    this.resourcesId = id;
    this.datumParams.resourcesId = this.resourceParams.resourcesId = id;
    await this.getResourceData(id);
    await this.getUserStudyInfo();
    await this.getPartyStudyPerceptionData();
    //获取当前登录用户的学习心得
    // await getPartyStudyExperience({})
    // await this.showExperience_click(this.userInfo.userId,false);
    // console.log(this.me_experienceData)

  },
  methods: {
    // 获取资源
    async getResourceData(id) {
      await getDetail({id}).then((res) => {
        let data = res.data;
        data.previewUrl = `http://139.155.146.142:8012/onlinePreview?url=${encodeURIComponent(
            btoa(data.url)
        )}`;
        let now = new Date().getTime();
        let start = new Date(data.startTime).getTime();
        let end = new Date(data.endTime).getTime();
        if (start > now) {
          data.state = 'waiting';
        } else if (start < now && now < end) {
          data.state = 'underway';
        } else {
          data.state = 'finished';
        }
        this.resourceData = data;
      });
    },
    // 查询本人学习感悟、学习心得
    async getUserStudyInfo() {
      this.loading = true;
      let params = {
        resourcesId: this.resourceData.id,
        userId: this.userInfo.user_id,
      };
      await getUserExperience(params).then((res) => {
        this.userExperienceData = res.data.records[0]
            ? res.data.records[0]
            : {};
        if (res.data.records.length > 0) {
          let urls = res.data.records[0].url.split(',');
          this.limit = 5 - urls.length;
          let previewUrl = [];
          urls.forEach((url) => {
            previewUrl.push(
                `http://139.155.146.142:8012/onlinePreview?url=${encodeURIComponent(
                    btoa(url)
                )}`
            );
            this.userExperienceData.previewUrl = previewUrl;
          });
        }
      });
      console.log(this.userExperienceData)
      await getUserPerception(params).then((res) => {
        this.userPerceptionData = res.data.records;
      });
      this.loading = false;
    },
    // 获取资源学习感悟、学习心得
    async getPartyStudyPerceptionData() {
      await getPartyStudyPerception(this.datumParams).then((res) => {
        this.perceptionData = res.data.records;
        this.datumParams.total = res.data.total;
      });
    },
    // 记录学习
    async study_click() {
      this.$refs['mask'].style.display = 'none';
      await recordUserStudy({
        userId: this.userInfo.user_id,
        deptId: this.userInfo.dept_id,
        resourcesId: this.resourcesId,
      })
          .then(() => {
            this.$message.success('已记录学习');
          })
          .catch(() => {
            this.$message.error('记录学习失败，请稍后重试');
          });
      if (this.resourceData.url)
        window.open(this.resourceData.previewUrl, '_blank');
    },
    // 提交学习感悟
    async submitPerception_click() {
      let params = {
        userId: this.userInfo.user_id,
        deptId: this.userInfo.dept_id,
        isExamine: 2,
        resourcesId: this.resourcesId,
        content: this.perceptionValue,
      };
      await addLearningPerception(params)
          .then(() => {
            this.$message.success('提交成功');
          })
          .catch(() => {
            this.$message.error('提交失败，请稍后重试');
          });
      this.perceptionValue = null;
      await this.getUserStudyInfo();
      await this.getPartyStudyPerceptionData();
    },
    showDialog_click() {
      this.showDialog = true;
    },
    // 点击查看学习心得
    async showExperience_click(userId, showDialog) {
      let params = {
        userId,
        resourcesId: this.resourcesId,
        isExamine: 1,
        status: 1,
      };

      if (userId == this.userInfo.user_id) {
        params.isExamine = null;
        params.status = null;

      }
      await getPartyStudyExperience(params).then((res) => {
        let data = res.data.records[0];
        if (data) {
          let urls = data.url.split(',');
          let previewUrl = [];
          urls.forEach((url) => {
            previewUrl.push(
                `http://139.155.146.142:8012/onlinePreview?url=${encodeURIComponent(
                    btoa(url)
                )}`
            );
            data.previewUrl = previewUrl;
          });
        }

          this.experienceData = data;


        this.showExperience = showDialog;
      });
    },
    //大文件上传
    clear() {
      this.filesLength = 0;
    },
    beforeUpload(file) {
      this.filesLength += 1;
      if (this.filesLength > this.limit) {
        return this.$message.warning(`目前只能再上传${this.limit}个文件`);
      }
      this.resourcesUrlHide = false;
      this.resourceParams.url = '';
      let i = file.name.split('').reverse().join('').indexOf('.');
      this.$set(
          this.resourceParams,
          'name',
          file.name.substring(0, file.name.length - i - 1)
      );
      this.resourceParams.size = (file.size / 1024).toFixed(2);
      if (!file.size) {
        setTimeout(() => {
          this.$notify({
            message: '不能上传大小为0的文件',
            type: 'warning',
            offset: 50,
          });
        }, 500);
        return false;
      }
      return this.propertyRestrictions(file);
    },
    // 文件个数限制钩子
    fileLimitFn() {
      this.$message.warning(`最多支持选择${this.limit}个文件`);
    },
    // 上传成功钩子
    async success(msg) {
      let url = msg.map((item) => {
        return item.url;
      });
      this.resourceParams.url = url.join(',');
      if (this.userExperienceData.url) {
        let params = {
          id: this.userExperienceData.id,
          url: `${this.userExperienceData.url},${url}`,
        };
        await updatePartyStudyExperience(params);
      } else {
        // 新增
        await addPartyStudyExperience(this.resourceParams);
      }
      this.resourcesUrlHide = true;
      this.$message.success('上传成功');
      await this.getUserStudyInfo();
      this.filesLength = 0;
      this.showDialog = false;
    },
    // 属性限制
    async propertyRestrictions(file) {
      return new Promise((resolve, reject) => {
        if (this.uploadType === 'image') {
          const isVerifyResolution = this.verifyResolution(file);
          if (!isVerifyResolution) {
            this.$message('不支持上传4K视频');
            reject();
          }
        }
        resolve(true);
      });
    },
    current_change(current) {
      this.datumParams.current = current;
      this.getPartyStudyPerceptionData();
    },
    async deletePerception_click(data) {
      await this.$confirm('确认删除选中的感悟？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      })
          .then(async () => {
            await deleteLearningPerception({id: data.id})
                .then(() => {
                  this.$message.success('删除成功！');
                  this.getResourceData();
                })
                .catch(() => {
                  this.$message.error('删除失败，请稍后重试！');
                });
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已取消删除',
            });
          });

      await this.getUserStudyInfo();
      await this.getPartyStudyPerceptionData();
    },
    resourceLoad(data) {
      console.log(data);
    },
  },
};
</script>

<style scoped>
.content-box {
  width: 100%;
  padding-top: 20px;
}

.operation-box {
  width: 100%;
  justify-content: space-between;
}

.title-box {
  width: 100%;
  font-size: 40px;
  text-align: center;
}

.introduce-box {
  white-space: pre-wrap;
  line-height: 24px;
}

.inspiration-box,
.experience-box {
  width: 100%;
}

.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  flex-shrink: 0;
}

.submit-btn {
  align-self: end;
  background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
}

.experience-item-box {
  width: 300px;
  height: 138px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

i {
  color: #dd2622;
}

.resource-box {
  position: relative;
}

.study-btn {
  position: absolute;
  background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}

.exhibition-box {
  width: 100%;
}

.info-box {
  /* align-items: center; */
  justify-content: space-between;
}

.exhibition-content {
  margin-left: 36px;
}

.show-experience-btn {
  align-self: end;
  background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
}

.resource {
  display: block;
  margin: 0 auto;
  height: 500px;
  max-width: 100%;
}

.paging {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #dd2622;
}
</style>
